import {
  ApproveIcon,
  CancelIcon,
  DeleteIcon,
  EditIcon,
  GridActionsCellItem,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
  SaveIcon,
  Tooltip,
  useUserRoles,
} from '@sgde/core';
import { Dispatch, SetStateAction } from 'react';
import { useStyles } from '../components/resources/ResourcesTable.styles.ts';
import { ResourceDto, ResourceState as ResourceStateEnum } from '../models/dto/chat/resource.ts';

type IActionsProps = {
  setRowModesModel: Dispatch<SetStateAction<GridRowModesModel>>;
  updateResource: (id: GridRowId, newState: ResourceStateEnum) => void;
  deleteResource: ({ id }: { id: number }) => void;
  removeLocalResource?: (id: number) => void;
};

export const useActions = ({
  setRowModesModel,
  updateResource,
  deleteResource,
  removeLocalResource,
}: IActionsProps) => {
  const { classes } = useStyles();
  const { hasRole } = useUserRoles();

  const handleEditClick = (id: GridRowId) => () =>
    setRowModesModel(rowModesModel => ({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } }));

  const handleDeleteClick = (id: GridRowId) => () => deleteResource({ id: id as number });

  const handleApproveClick = (id: GridRowId) => () => updateResource?.(id as number, ResourceStateEnum.Public);

  const handleSaveClick = (id: GridRowId) => () =>
    setRowModesModel(rowModesModel => ({ ...rowModesModel, [id]: { mode: GridRowModes.View } }));

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel(rowModesModel => ({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));

    if (removeLocalResource && !id) {
      removeLocalResource(id as number);
    }
  };

  const getEditActions = (id: GridRowId) => {
    return [
      <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} color="inherit" />,
      <GridActionsCellItem icon={<CancelIcon />} label="Cancel" onClick={handleCancelClick(id)} color="inherit" />,
    ];
  };

  const getDefaultActions = (id: GridRowId, row: ResourceDto) => {
    const rowType = ResourceStateEnum[row.state as unknown as keyof typeof ResourceStateEnum];
    const isApproveDisabled = rowType !== ResourceStateEnum.Active;

    if (hasRole('Admin')) {
      return [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={handleEditClick(id)}
          className={classes.disabled}
          color="inherit"
          disabled={!row.state}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={handleDeleteClick(id)}
          className={classes.disabled}
          color="inherit"
          disabled={!row.state}
        />,
        <Tooltip
          title={
            rowType >= ResourceStateEnum.Active
              ? 'Publica'
              : 'Resursa trebuia sa fie Activa pentru a putea fi publicata'
          }
        >
          <GridActionsCellItem
            icon={<ApproveIcon />}
            label="Approve"
            onClick={handleApproveClick(id)}
            className={classes.disabled}
            color="inherit"
            component={isApproveDisabled ? 'span' : undefined}
            disabled={isApproveDisabled}
          />
        </Tooltip>,
      ];
    }
    return [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Edit"
        onClick={handleEditClick(id)}
        className={classes.disabled}
        color="inherit"
        disabled={!row.state}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Delete"
        onClick={handleDeleteClick(id)}
        className={classes.disabled}
        color="inherit"
        disabled={!row.state}
      />,
    ];
  };

  return { getEditActions, getDefaultActions };
};
