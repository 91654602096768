import { BaseDto, IEditable } from '@sgde/core';

export type ResourceDto = BaseDto &
  Partial<IEditable> & {
    title: string;
    author?: string;
    identifier?: string;
    identifierType?: ResourceIdentifierType;
    addedByUserEmail?: string;
    organisationIds?: number[];
    state?: ResourceState;
    file?: File;
    url?: string;
  };

export enum ResourceState {
  ProcessingFailed = -2,
  InsertFailed = -1,
  Inserted = 0,
  MissingDetails = 1,
  Processing = 2,
  Active = 3,
  Public = 4,
}

export enum ResourceIdentifierType {
  ISBN,
  DOI,
  ISSN,
}
