import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { AdapterDayjs, DatePicker, LocalizationProvider } from './external/MuiX';

interface IDatePickerProps {
  value: string | null;
  label: string;
  minDate?: Date;
  disabled: boolean;
  onDateChange?: (date: string) => void;
  onDateError?: (error?: string | null) => void;
  errorMessage?: string;
}

export const MuiXDatePicker = ({
  value,
  label,
  minDate,
  disabled,
  errorMessage,
  onDateChange,
  onDateError,
}: IDatePickerProps) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(value ? dayjs(value) : null);

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      setSelectedDate(dayjs(newDate));
      if (onDateChange) {
        onDateChange(newDate.toDate().toISOString());
      }
    }
  };

  useEffect(() => {
    if (value) {
      handleDateChange(dayjs(value));
    }
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        disabled={disabled}
        label={label}
        minDate={dayjs(minDate)}
        value={selectedDate}
        onChange={handleDateChange}
        onError={onDateError ? newError => onDateError(newError) : undefined}
        slotProps={{
          textField: {
            helperText: errorMessage,
          },
        }}
        format="DD/MM/YYYY"
      />
    </LocalizationProvider>
  );
};
