import { baseApi, BaseDto, useAppDispatch } from '@sgde/core';
import { useState } from 'react';
import { ResourceState } from '../models/dto/chat/resource.ts';
import { WebPageDto } from '../models/dto/chat/webPage.ts';

const webPageResourceApi = baseApi.enhanceEndpoints({ addTagTypes: ['WebPageResources'] }).injectEndpoints({
  endpoints: build => ({
    getWebPageResources: build.query<WebPageDto[], void>({
      query: () => '/chat/resources/web-pages',
      providesTags: ['WebPageResources'],
    }),
    createWebPageResource: build.mutation<WebPageDto, WebPageDto>({
      query: resource => ({
        url: `/chat/resources/web-page`,
        method: 'POST',
        body: resource,
      }),
      invalidatesTags: ['WebPageResources'],
    }),
    updateWebPageResource: build.mutation<void, WebPageDto>({
      query: resource => ({
        url: `/chat/resources/web-page/${resource.id}`,
        method: 'PUT',
        body: resource,
      }),
      invalidatesTags: ['WebPageResources'],
    }),
    deleteWebPageResource: build.mutation<void, BaseDto>({
      query: ({ id }) => ({ url: `/chat/resources/web-page/${id}`, method: 'DELETE' }),
      invalidatesTags: ['WebPageResources'],
    }),
  }),
});

export const {
  useGetWebPageResourcesQuery: useWebPageResources,
  useCreateWebPageResourceMutation: useCreateWebPageResource,
  useUpdateWebPageResourceMutation: useUpdateWebPageResource,
  useDeleteWebPageResourceMutation: useDeleteWebPageResource,
} = webPageResourceApi;

export const useCreateWebPageResourceLocally = () => {
  const dispatch = useAppDispatch();
  const [newWebPageResource, setNewWebPageResource] = useState<WebPageDto | undefined>();
  return [
    () =>
      dispatch(
        webPageResourceApi.util.updateQueryData('getWebPageResources', undefined, resources => {
          const localResources = resources?.find(resource => !resource.id);
          if (!localResources) {
            resources.unshift(DEFAULT_WEB_PAGE_RESOURCE);
            setNewWebPageResource(DEFAULT_WEB_PAGE_RESOURCE);
          }
        })
      ),
    { data: newWebPageResource },
  ] as const;
};

export const useDeleteWebPageResourceLocally = () => {
  const dispatch = useAppDispatch();
  return () =>
    dispatch(
      webPageResourceApi.util.updateQueryData('getWebPageResources', undefined, resources => {
        const localResource = resources?.find(resource => !resource.id);
        if (localResource) {
          resources.shift();
        }
      })
    );
};

const DEFAULT_WEB_PAGE_RESOURCE = {
  id: 0,
  title: '',
  url: '',
  isRecursive: false,
  createdAt: new Date().toISOString(),
  organisationIds: [],
  state: ResourceState.Processing,
} as WebPageDto;
