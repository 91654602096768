import {
  Box,
  Divider,
  IconButton,
  InfoOutlinedIcon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SendIcon,
  Stack,
  TextField,
  Typography,
  UploadIcon,
  useNavigate,
  useSite,
} from '@sgde/core';
import { MouseEvent, useMemo, useState } from 'react';
import { ConversationDto } from '../../models/dto/chat/conversation.ts';
import { ROUTES } from '../../router/routes.ts';
import { useCreateConversation, useMessageResponse, useUserChatInfo } from '../../store/chatApi.ts';
import { useStyles } from './Conversation.styles.ts';
import { Messages } from './Messages.tsx';

type Props = {
  conversation: ConversationDto;
  organisationId?: number;
};

export const Conversation = ({ conversation, organisationId }: Props) => {
  const { data: site } = useSite();
  const [getResponse, { isCompleted: isResponding }] = useMessageResponse();
  const [request, setRequest] = useState('');
  const [createConversation] = useCreateConversation();
  const { data: userChatInfo } = useUserChatInfo();
  const color = usePromptCounterColor(userChatInfo?.promptCounter, userChatInfo?.promptLimit);
  const counterText = `${userChatInfo?.promptCounter || 0} din ${userChatInfo?.promptLimit || 0} mesaje`;
  const { classes } = useStyles({ counterText, color });

  const isValid = useMemo(
    () =>
      isResponding && request.trim().length && (userChatInfo?.promptCounter ?? 0) < (userChatInfo?.promptLimit ?? 0),
    [isResponding, request, userChatInfo]
  );

  const handleResponse = async () => {
    let conversationId = conversation.id;
    if (!conversation.id) {
      const response = await createConversation({ conversation: { name: request }, organisationId });
      if ('data' in response) conversationId = response.data.id;
    }
    getResponse({ id: conversationId, text: request, type: 'User', organisationId: organisationId });
    setRequest('');
  };

  return (
    <Box className={classes.root}>
      <Stack>
        <Messages conversation={conversation} />
        <Box className={classes.inputWrapper}>
          <TextField
            fullWidth
            className={classes.input}
            placeholder="Mesaj"
            multiline
            maxRows={8}
            value={request}
            InputProps={{
              startAdornment: <ConversationResources className={classes.adornment} />,
              endAdornment: (
                <IconButton disableRipple disabled={!isValid} onClick={handleResponse} className={classes.adornment}>
                  <SendIcon />
                </IconButton>
              ),
            }}
            onChange={({ target: { value } }) => setRequest(value)}
            onKeyDown={event => {
              if (isValid && event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                handleResponse();
              }
            }}
          />
          <Stack direction="row" className={classes.info}>
            <InfoOutlinedIcon fontSize="small" />
            <Typography variant="caption">
              {site?.title} poate face greșeli. Recomandăm verificarea informațiilor importante.
            </Typography>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

const usePromptCounterColor = (promptCounter: number = 0, promptLimit: number = 0) => {
  const messagesLeft = promptLimit - promptCounter;
  switch (true) {
    case messagesLeft <= 5:
      return 'error';
    case messagesLeft <= 10:
      return 'warning';
    default:
      return 'success';
  }
};

type ConversationResourcesProps = {
  className: string | undefined;
};
const ConversationResources = ({ className }: ConversationResourcesProps) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton disableRipple className={className} onClick={handleClick}>
        <UploadIcon />
      </IconButton>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem>
          <ListItemIcon>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Fisier</ListItemText>
        </MenuItem>
        <Divider textAlign="center">
          <Typography variant="caption" color="textSecondary">
            In colectia mea
          </Typography>
        </Divider>
        <MenuItem onClick={() => navigate(ROUTES.RESOURCES.path)}>
          <ListItemIcon>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Vezi resurse</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate(ROUTES.RESOURCES.path, { state: { initUpload: true } })}>
          <ListItemIcon>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Fisier</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Pagina web</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Baza de date</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
