import { GridRenderEditCellParams, MenuItem, Select, useGridApiContext } from '@sgde/core';
import { ResourceState as ResourceStateEnum } from '../../models/dto/chat/resource.ts';
import { useOrganisations } from '../../store/organisationApi.ts';

export const OrganisationsEditCell = ({ id, value, field, row }: GridRenderEditCellParams) => {
  const apiRef = useGridApiContext();
  const { data: organisations } = useOrganisations();
  const handleChange = (value: number[]) => {
    const orgIds = value?.length ? value.filter(orgId => !!orgId) : [];
    apiRef.current.setEditCellValue({ id, field, value: orgIds });
  };

  return (
    <Select
      disabled={ResourceStateEnum[row.state as unknown as keyof typeof ResourceStateEnum] !== ResourceStateEnum.Active}
      multiple
      value={value}
      onChange={({ target: { value } }) => handleChange(value)}
      fullWidth
    >
      {organisations?.map(org => (
        <MenuItem key={org.id} value={org.id}>
          {org.name}
        </MenuItem>
      ))}
    </Select>
  );
};
