import { GridEditInputCell, GridRenderEditCellParams, validateField } from '@sgde/core';
import { useState } from 'react';
import { WebPageDto } from '../../models/dto/chat/webPage.ts';
import { EditCellWrapper } from './EditCellWrapper.tsx';

export const WebPageUrlEditCell = (props: GridRenderEditCellParams) => {
  const [urlValue, setUrlValue] = useState(props.row.url);
  const [hasErrors, setHasErrors] = useState(false);

  const handleValueChange = (newValue: string) => {
    const errorMessage = validateField<WebPageDto>('url', newValue);
    if (errorMessage) {
      setHasErrors(true);
    } else {
      setHasErrors(false);
    }
    setUrlValue(newValue);
  };

  return (
    <EditCellWrapper hasErrors={hasErrors}>
      <GridEditInputCell
        {...props}
        field="url"
        value={urlValue}
        onValueChange={(e: React.ChangeEvent<HTMLInputElement>) => handleValueChange(e.target.value)}
        colDef={{ ...props.colDef, field: 'url' }}
      />
    </EditCellWrapper>
  );
};
