import { Avatar, CopyToClipboard, Stack, ThemeMode, Typography, useSite, useTheme } from '@sgde/core';
import DOMPurify from 'dompurify';
import hljs from 'highlight.js';
import { Marked } from 'marked';
import { markedHighlight } from 'marked-highlight';
import { ReactElement, useEffect, useState } from 'react';
import { FeedbackDto } from '../../models/dto/chat/feedback.ts';
import { useStyles } from './Message.styles.ts';
import { MessageFeedback } from './MessageFeedback.tsx';

import darkTheme from 'highlight.js/styles/github-dark-dimmed.css?inline';
import lightTheme from 'highlight.js/styles/github.css?inline';

type MessageProps = {
  variant: 'question' | 'response';
  message: string;
  feedback?: FeedbackDto;
};

export const Message = ({ variant, message, feedback }: MessageProps) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const { data: site } = useSite();
  const { format } = useMessageFormatter();
  const [avatar, setAvatar] = useState<string>();

  useEffect(() => {
    if (site) {
      setAvatar((theme?.palette.mode === ThemeMode.Dark && site.faviconDark ? site.faviconDark : site.favicon).url);
    }
  }, [site, theme?.palette.mode]);

  return (
    <Stack direction="row" className={classes.message}>
      <Avatar className={classes.avatar} src={variant === 'question' ? undefined : avatar} />
      <Stack gap={1}>
        {format(message)}
        {variant === 'response' && (
          <Stack direction="row" gap={1}>
            <CopyToClipboard text={message} />
            <MessageFeedback {...feedback} />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

const DOMPurifyConfig = { USE_PROFILES: { html: true } };
const postprocess = (dirtyMessage: string) => DOMPurify.sanitize(dirtyMessage, DOMPurifyConfig);
const markedOptions = {
  gfm: true,
  breaks: true,
  hooks: { postprocess },
};
const useMessageFormatter = () => {
  const [marked, setMarked] = useState<Marked | undefined>();
  const theme = useTheme();

  useEffect(() => {
    const marked = new Marked(
      markedHighlight({
        emptyLangClass: 'hljs',
        langPrefix: 'hljs language-',
        highlight(code, lang) {
          const language = hljs.getLanguage(lang) ? lang : 'plaintext';
          return hljs.highlight(code, { language }).value;
        },
      })
    );
    marked.use(markedOptions);
    setMarked(marked);
  }, []);

  return {
    format: (message: string): ReactElement => (
      <>
        <style>{theme?.palette.mode === ThemeMode.Dark ? darkTheme : lightTheme}</style>
        <Typography component="div" dangerouslySetInnerHTML={{ __html: marked?.parse(message) ?? '' }} />
      </>
    ),
  };
};
