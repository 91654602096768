import { ImageDto, Stack, ThemeMode, useLocation, useNavigate, useSite, useTheme } from '@sgde/core';
import { useEffect, useState } from 'react';
import { ROUTES } from '../router/routes.ts';
import { useStyles } from './Logo.styles.ts';

export const Logo = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { data: site } = useSite();
  const [logo, setLogo] = useState<ImageDto>();

  useEffect(() => {
    if (site) {
      setLogo(theme?.palette.mode === ThemeMode.Dark && site.logoDark ? site.logoDark : site.logo);
    }
  }, [site, theme?.palette.mode]);

  const navigateHome = () =>
    navigate([ROUTES.HOME.path, ROUTES.CHAT.path].includes(pathname) ? ROUTES.HOME.path : ROUTES.CHAT.path);

  return (
    <Stack className={classes.root} direction="row" alignItems="center" onClick={navigateHome}>
      {logo && <img src={logo.url} alt={logo.alternativeText ?? logo.name} />}
    </Stack>
  );
};
