import {
  Box,
  CancelIcon,
  DataGrid,
  DeleteIcon,
  EditIcon,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridSlots,
  SaveIcon,
  Stack,
  UserDto,
  useUserRoles,
} from '@sgde/core';
import { Dispatch, SetStateAction, useState } from 'react';
import { OrganisationDto } from '../../models/dto/organisation/organisation.ts';
import {
  useCreateOrganisation,
  useDeleteOrganisation,
  useDeleteOrganisationLocally,
  useOrganisations,
  useUpdateOrganisation,
} from '../../store/organisationApi.ts';
import { useStyles } from './OrganisationsTable.styles.ts';
import { Toolbar } from './OrganisationsTableToolbar.tsx';

export const OrganisationsTable = () => {
  const { classes } = useStyles();
  const { data: organisations } = useOrganisations();
  const [createOrganisation] = useCreateOrganisation();
  const [updateOrganisations] = useUpdateOrganisation();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 25 });

  const { columns } = useColumns({ rowModesModel, setRowModesModel });

  const processRowUpdate = (newRow: GridRowModel<OrganisationDto>) => {
    if (!newRow.id) {
      createOrganisation(newRow);
    } else {
      updateOrganisations(newRow);
    }

    return newRow;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  return (
    <DataGrid
      className={classes.root}
      editMode="row"
      columns={columns}
      rows={organisations ?? []}
      hideFooterSelectedRowCount
      rowModesModel={rowModesModel}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      onRowModesModelChange={setRowModesModel}
      slots={{
        toolbar: Toolbar as GridSlots['toolbar'],
        noRowsOverlay: NoRowsOverlay,
        noResultsOverlay: NoResultsOverlay,
      }}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      slotProps={{ toolbar: { setRowModesModel, setPaginationModel } }}
      columnVisibilityModel={{ identifierType: false }}
    />
  );
};

type IActionsProps = {
  setRowModesModel: Dispatch<SetStateAction<GridRowModesModel>>;
};
const useActions = ({ setRowModesModel }: IActionsProps) => {
  const { hasRole } = useUserRoles();
  const [deleteOrganisation] = useDeleteOrganisation();
  const deleteOrganisationLocally = useDeleteOrganisationLocally();

  const handleEditClick = (id: GridRowId) => () =>
    setRowModesModel(rowModesModel => ({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } }));

  const handleDeleteClick = (id: GridRowId) => () => deleteOrganisation([id as number]);

  const handleSaveClick = (id: GridRowId) => () =>
    setRowModesModel(rowModesModel => ({ ...rowModesModel, [id]: { mode: GridRowModes.View } }));

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel(rowModesModel => ({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));

    if (!id) {
      deleteOrganisationLocally();
    }
  };

  const getEditActions = (id: GridRowId) => [
    <GridActionsCellItem icon={<SaveIcon />} label="Save" onClick={handleSaveClick(id)} color="inherit" />,
    <GridActionsCellItem icon={<CancelIcon />} label="Cancel" onClick={handleCancelClick(id)} color="inherit" />,
  ];

  const getDefaultActions = (id: GridRowId) => {
    if (hasRole('Admin')) {
      return [
        <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleEditClick(id)} color="inherit" />,
        <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />,
      ];
    }
    return [
      <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleEditClick(id)} color="inherit" />,
      <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />,
    ];
  };

  return { getEditActions, getDefaultActions };
};

interface IColumnProps {
  rowModesModel: GridRowModesModel;
  setRowModesModel: Dispatch<SetStateAction<GridRowModesModel>>;
}
const useColumns = ({ rowModesModel, setRowModesModel }: IColumnProps) => {
  const { getDefaultActions, getEditActions } = useActions({ setRowModesModel });

  const columns = [
    {
      field: 'name',
      headerName: 'Nume',
      disableColumnMenu: true,
      editable: true,
      flex: 3,
    },
    {
      field: 'owner',
      headerName: 'Gerant',
      disableColumnMenu: true,
      editable: true,
      flex: 2,
      valueGetter: value => (value as UserDto)?.email,
      valueSetter: (value, row) => ({ ...row, owner: { email: value } }),
    },
    {
      field: 'users',
      headerName: 'Nr. Utilizatori',
      disableColumnMenu: true,
      valueGetter: value => (value as [])?.length,
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Adaugat la',
      valueGetter: value => new Date(value ?? Date.now()).toLocaleDateString('ro-RO'),
      disableColumnMenu: true,
      flex: 1.5,
    },
    {
      field: 'actions',
      type: 'actions',
      resizable: false,
      getActions: ({ id }) =>
        rowModesModel[id]?.mode === GridRowModes.Edit ? getEditActions(id) : getDefaultActions(id),
    },
  ] as GridColDef[];

  return {
    columns,
  };
};

const NoRowsOverlay = () => (
  <Stack alignItems="center" justifyContent="center" height="100%">
    <Box>Nu există organizații disponibile. Pentru a adăuga noi organizații, apăsați butonul '+'.</Box>
  </Stack>
);

const NoResultsOverlay = () => (
  <Stack alignItems="center" justifyContent="center" height="100%">
    <Box>Nu au fost găsite organizații.</Box>
  </Stack>
);
