import {
  AddCommentIcon,
  ChevronDownIcon,
  DeleteIcon,
  Divider,
  Drawer,
  HistoryIcon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  QuestionAnswerIcon,
  useHeader,
} from '@sgde/core';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ConversationDto } from '../../models/dto/chat/conversation.ts';
import { useConversations, useCreateConversationLocally, useDeleteConversation } from '../../store/chatApi.ts';
import { useOrganisations } from '../../store/organisationApi.ts';
import { useStyles } from './Conversations.styles.ts';

interface IProps {
  conversation: ConversationDto;
  onChangeConversation: Dispatch<SetStateAction<ConversationDto>>;
  onChangeOrganisation: Dispatch<SetStateAction<number | undefined>>;
}

export const Conversations = ({ conversation, onChangeConversation, onChangeOrganisation }: IProps) => {
  const { classes } = useStyles();
  const { setMenuItems } = useHeader();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { data: organisations } = useOrganisations();
  const [deleteConversation] = useDeleteConversation();
  const [createConversationLocally] = useCreateConversationLocally();
  const [organisationIndex, setOrganisationIndex] = useState<number | undefined>(
    Number(sessionStorage.getItem('organisationIndex')) || undefined
  );
  const { data: conversations } = useConversations({ organisationId: organisations?.[organisationIndex ?? -1]?.id });
  const [organisationMenuAnchor, setOrganisationMenuAnchor] = useState<null | HTMLElement>(null);
  const openOrganisationMenu = Boolean(organisationMenuAnchor);

  useEffect(() => {
    setDrawerOpen(false);
  }, [conversation]);

  useEffect(() => {
    if (conversations && !conversations.length) {
      createConversationLocally({ organisationId: organisations?.[organisationIndex ?? -1]?.id });
    }
  }, [conversations]);

  useEffect(() => {
    const normalizedIndex = organisationIndex !== undefined && organisationIndex >= 0 ? organisationIndex : -1;
    onChangeOrganisation(organisations?.[normalizedIndex]?.id);
  }, [onChangeOrganisation, organisationIndex, organisations]);

  useEffect(() => {
    sessionStorage.setItem('organisationIndex', organisationIndex?.toString() ?? '');
  }, [organisationIndex]);

  useEffect(() => {
    const conversationsHistory = (
      <IconButton onClick={() => setDrawerOpen(open => !open)} className={classes.conversationsHistoryButton}>
        <HistoryIcon />
      </IconButton>
    );

    setMenuItems([conversationsHistory]);
  }, [classes.conversationsHistoryButton, setMenuItems]);

  const changeConversation = (conversationId?: number) => {
    if (!conversationId) createConversationLocally({ organisationId: organisations?.[organisationIndex ?? -1]?.id });
    onChangeConversation(
      conversations?.find(conversation => conversation.id === conversationId) ?? { id: 0, name: 'Conversatie noua' }
    );
  };

  return (
    <Drawer anchor="right" isOpen={drawerOpen} close={() => setDrawerOpen(false)}>
      <List disablePadding>
        <ListItem disablePadding sx={{ alignItems: 'stretch' }}>
          <ListItemButton component="button" onClick={() => conversation && changeConversation(undefined)}>
            <ListItemIcon className={classes.conversationIcon}>
              <AddCommentIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              className={classes.conversationName}
              primary="Conversatie noua"
              secondary={
                organisationIndex !== undefined && organisationIndex >= 0 && organisations
                  ? organisations[organisationIndex]?.name
                  : 'Personal'
              }
            />
          </ListItemButton>
          <Divider orientation="vertical" variant="middle" sx={{ height: 'unset' }} />
          <ListItemButton component="button" onClick={event => setOrganisationMenuAnchor(event.currentTarget)}>
            <ListItemIcon className={classes.conversationIcon}>
              <ChevronDownIcon />
            </ListItemIcon>
          </ListItemButton>
          <Menu
            anchorEl={organisationMenuAnchor}
            open={openOrganisationMenu}
            onClose={() => setOrganisationMenuAnchor(null)}
          >
            <MenuItem
              selected={!organisationIndex}
              onClick={() => {
                setOrganisationIndex(undefined);
                setOrganisationMenuAnchor(null);
              }}
            >
              Personal
            </MenuItem>
            {organisations?.map((organisation, index) => (
              <MenuItem
                key={organisation.id}
                selected={index === organisationIndex}
                onClick={() => {
                  setOrganisationIndex(index);
                  setOrganisationMenuAnchor(null);
                }}
              >
                {organisation.name}
              </MenuItem>
            ))}
          </Menu>
        </ListItem>
        <Divider component="li" sx={{ margin: '4px 0' }} />
        {conversations?.map(c => (
          <ListItem
            key={c.id}
            className={classes.conversation}
            disablePadding
            secondaryAction={
              <IconButton edge="end" onClick={() => deleteConversation({ id: c.id })}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            }
          >
            <ListItemButton key={c.id} selected={conversation?.id === c.id} onClick={() => changeConversation(c.id)}>
              <ListItemIcon className={classes.conversationIcon}>
                <QuestionAnswerIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText className={classes.conversationName} primary={c.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};
