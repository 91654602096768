import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  box: {
    width: '100%',
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: theme.spacing(1.5),
  },
}));
