import { GridEditInputCell, GridRenderEditCellParams, SelectChangeEvent, useGridApiContext } from '@sgde/core';
import { useState } from 'react';
import { ResourceIdentifierType } from '../../models/dto/chat/resource.ts';

export const IdentifierEditCell = (props: GridRenderEditCellParams) => {
  const apiRef = useGridApiContext();
  const [identifierValue, setIdentifierValue] = useState(props.row.identifier);
  const handleValueChange = async (_: SelectChangeEvent, newValue: string) => {
    setIdentifierValue(newValue);
    const isbnPattern =
      /^(?=[0-9X]{10}$|(?=(?:[0-9]+[- ]){3})[- 0-9X]{13}$|97[89][0-9]{10}$|(?=(?:[0-9]+[- ]){4})[- 0-9]{17}$)(?:97[89][- ]?)?[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9X]$/i;
    const issnPattern = /^\d{4}-\d{3}(\d|X)$/;
    const doiPattern = /^10\.(\d+\.*)+[/](([^\s.])+\.*)+$/i;

    await apiRef.current.setEditCellValue({
      id: props.id,
      field: 'identifierType',
      value: issnPattern.test(newValue)
        ? ResourceIdentifierType.ISSN
        : doiPattern.test(newValue)
          ? ResourceIdentifierType.DOI
          : isbnPattern.test(newValue)
            ? ResourceIdentifierType.ISBN
            : undefined,
    });
  };

  return (
    <GridEditInputCell
      {...props}
      field="identifier"
      value={identifierValue}
      onValueChange={handleValueChange}
      colDef={{ ...props.colDef, field: 'identifier' }}
    />
  );
};
