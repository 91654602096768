import { GridEditInputCell, GridRenderEditCellParams, validateField } from '@sgde/core';
import { useState } from 'react';
import { WebPageDto } from '../../models/dto/chat/webPage.ts';
import { EditCellWrapper } from './EditCellWrapper.tsx';

export const WebPageTitleEditCell = (props: GridRenderEditCellParams) => {
  const [titleValue, setTitleValue] = useState(props.row.title);
  const [hasErrors, setHasErrors] = useState(false);

  const handleValueChange = (newValue: string) => {
    const errorMessage = validateField<WebPageDto>('title', newValue);
    if (errorMessage) {
      setHasErrors(true);
    } else {
      setHasErrors(false);
    }
    setTitleValue(newValue);
  };

  return (
    <EditCellWrapper hasErrors={hasErrors}>
      <GridEditInputCell
        {...props}
        field="title"
        value={titleValue}
        onValueChange={(e: React.ChangeEvent<HTMLInputElement>) => handleValueChange(e.target.value)}
        colDef={{ ...props.colDef, field: 'title' }}
      />
    </EditCellWrapper>
  );
};
