import {
  AddIcon,
  ArrowDropDownIcon,
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useLocation,
  useNavigate,
} from '@sgde/core';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { menuOptions } from '../../constants/resources.ts';
import { useStyles } from './TableToolbar.styles.ts';

interface IProps {
  selectedMenu: string;
  setSelectedMenu: Dispatch<SetStateAction<string>>;
  menuActions: {
    addResource: (event: React.ChangeEvent<HTMLInputElement>) => void;
    addWebPage: () => void;
    addDatabase: () => void;
  };
  isLoading: boolean;
}

export const Toolbar = ({ selectedMenu, setSelectedMenu, menuActions, isLoading }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (location?.state?.initUpload && fileUploadRef.current) {
      navigate(location.pathname, { replace: true, state: null });
      fileUploadRef.current.click();
    }
  }, []);

  const handleMenuItemClick = (menuItem: string) => {
    setSelectedMenu(menuItem);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleClick = () => {
    switch (selectedMenu) {
      case menuOptions.FILE:
        fileUploadRef.current?.click();
        break;
      case menuOptions.WEB_PAGE:
        menuActions.addWebPage();
        break;
      case menuOptions.DATABASE:
        menuActions.addDatabase();
        break;
    }
  };

  return (
    <Box className={classes.root}>
      <ButtonGroup variant="contained" ref={anchorRef} aria-label="Button group with a nested menu">
        <Button onClick={handleClick} startIcon={<AddIcon />}>
          {selectedMenu}
          <input
            ref={fileUploadRef}
            type="file"
            className={classes.hiddenInput}
            onChange={e => menuActions.addResource(e)}
            multiple
            disabled={isLoading}
          />
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select an option"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper sx={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {Object.values(menuOptions).map(option => (
                    <MenuItem
                      key={option}
                      disabled={option === menuOptions.DATABASE}
                      selected={option === selectedMenu}
                      onClick={() => handleMenuItemClick(option)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
