import { BaseDto } from '@sgde/core';

export type CronJobDto = BaseDto & {
  startDate: string;
  endDate?: string;
  occurrenceType: ResourceOccurrenceType;
  occurrence: number;
  type: CronJobType;
};

export enum CronJobType {
  WebPage = 0,
  Database = 1,
}

export enum ResourceOccurrenceType {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
}
