import { alpha, makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  message: {
    gap: theme.spacing(3),
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      gap: theme.spacing(1),
    },
    '& .MuiTypography-root': {
      '& :first-child': {
        marginTop: 0,
      },
      '& :last-child': {
        marginBottom: 0,
      },
    },
    '& pre code.hljs': {
      padding: theme.spacing(2),
      boxShadow: theme.shadows[1],
      borderRadius: theme.shape.borderRadius,
      background: alpha(theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.primary.main, 0.1),
    },
    '& table': {
      padding: theme.spacing(2),
      boxShadow: theme.shadows[1],
      borderRadius: theme.shape.borderRadius,
      background: alpha(theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.primary.main, 0.1),
      '& th, td': {
        padding: theme.spacing(1 / 2),
        background: theme.palette.background.default,
      },
    },
  },

  avatar: {
    alignSelf: 'start',
    width: theme.spacing(4),
    height: theme.spacing(4),
    borderRadius: theme.spacing(1),
  },
}));
