import { Box } from '@sgde/core';
import { useStyles } from './EditCellWrapper.styles.ts';

interface IProps {
  hasErrors: boolean;
  children: React.ReactNode[] | React.ReactNode;
}

export const EditCellWrapper = ({ hasErrors, children }: IProps) => {
  const { classes } = useStyles();

  return <Box className={`${classes.box} ${hasErrors ? classes.error : ''}`}>{children}</Box>;
};
