import { makeStyles } from '@sgde/core';

export const useStyles = makeStyles()(theme => ({
  stack: {
    height: '100%',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  box: {
    padding: theme.spacing(0.6),
    display: 'flex',
  },
}));
